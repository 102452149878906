import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import ImageMeta from "../ImageMeta"
import TheaterVideo from "@components/Theater/TheaterVideo"
import ImageCaption from "./ImageCaption"

function FacewallImage(props) {
  let nameClasses = classNames("image-caption staff fixed-facewall-name", {
    last: !props.procedure && !props.monthYear
  })
  let procedureClasses = classNames(
    "image-caption staff fixed-facewall-procedure",
    {
      last: !props.monthYear
    }
  )
  let timeClasses = classNames("image-caption staff fixed-facewall-time", {
    last: true
  })

  let theaterClasses = classNames("facewall-theater", {
    "is-hidden-tablet": props.theaterMobileOnly
  })

  const playIcon = (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="play"
      class="svg-inline--fa fa-play fa-w-14 button-icon"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
      ></path>
    </svg>
  )

  let url = props.url
  if (url.charAt(0) !== "/") url = "/" + url

  return (
    <>
      <div
        className={`column is-6 fixed-facewall-item has-text-centered${
          props.columnClassName ? " " + props.columnClassName : ""
        }`}
      >
        <div className={props.className}>
          <Link
            title={`View the full testimonial made by ${props.reviewerName}`}
            to={url.toLowerCase()}
          >
            <div className="fixed-facewall-item__image-wrapper">
              <ImageMeta
                cloudName="nuvolum"
                publicId={props.imageUrl}
                data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto:best/v1/${props.imageUrl}`}
                width="auto"
                responsive
                responsiveUseBreakpoints="true"
                className="lazyload"
              ></ImageMeta>
            </div>
          </Link>
          {(props.reviewerName || props.procedure || props.monthYear) && (
            <Link
              title={`View the full testimonial made by ${props.reviewerName}`}
              className="image-caption-link"
              to={url.toLowerCase()}
            >
              <ImageCaption
                reviewerName={props.reviewerName}
                language={props.language}
                procedure={props.procedure}
                monthYear={props.monthYear}
                hideProcedureNames={props.hideProcedureNames}
              />
            </Link>
          )}
          {props.youtube && (
            <>
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${props.youtube}`}
                controls={true}
                playing={true}
                overVideo={false}
                hasWatchVideo={true}
                buttonClass="contained facewall-theater-btn is-hidden-tablet"
                containerClass="is-hidden-tablet"
              ></TheaterVideo>

              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${props.youtube}`}
                controls={true}
                playing={true}
                overVideo={false}
                hasWatchVideo={false}
                buttonClass="contained"
              >
                <div className="facewall-theater-btn is-hidden-mobile">
                  <a
                    aria-label="open video player"
                    role="button"
                    className="text-button"
                  >
                    {playIcon} Watch Video
                  </a>
                </div>
              </TheaterVideo>
            </>
          )}
        </div>
      </div>
    </>
  )
}

FacewallImage.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  procedure: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  reviewerName: PropTypes.string,
  language: PropTypes.string,
  monthYear: PropTypes.object
}

export default FacewallImage
